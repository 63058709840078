<template>
  <div>
    <h1>Welcome to the Home Page</h1>
    <button @click="goToDataPage">Go to API Page</button>
  </div>
</template>

<script>
export default {
  methods: {
    goToDataPage() {
      this.$router.push({ name: 'DataView' });
    },
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
button {
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}
</style>
