import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import DataView from '../views/DataView.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/api', name: 'DataView', component: DataView },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }, // Vue 3 用法
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;
