<template>
  <div>
    <h1>API Page</h1>
    <br><br><br><br>
    <!-- W1 Data Section -->
    <section>
      <h2>What day is it today ?</h2>
      <button @click="fetchData('w1')" :disabled="loading.w1">
        {{ loading.w1 ? 'Loading ...' : 'Fetch Week Data' }}
      </button>
      <div v-if="w1Data">
        <pre> result >> {{ w1Data }}</pre>
      </div>
      <div v-else-if="error.w1">
        <p style="color: red;">Error loading week data: {{ error.w1 }}</p>
      </div>
      <div v-else>Click button to fetch week data...</div>
    </section>

    <br>
    
    <!-- T1 Data Section -->
    <section>
      <h2>What time is it ?</h2>
      <button @click="fetchData('t1')" :disabled="loading.t1">
        {{ loading.t1 ? 'Loading ...' : 'Fetch Time Data' }}
      </button>
      <div v-if="t1Data">
        <pre> result >> {{ t1Data }}</pre>
      </div>
      <div v-else-if="error.t1">
        <p style="color: red;">Error loading time data: {{ error.t1 }}</p>
      </div>
      <div v-else>Click button to fetch time data...</div>
    </section>

    <br>
    
    <!-- S1 Data Section -->
    <section>
      <h2>Save log</h2>
      <input v-model="userInput" placeholder="Enter your name" /><br>
      <button @click="save">Save</button>
      <div v-if="responseMessage">
        <p>{{ responseMessage }}</p>
      </div>
      <div v-else-if="error.s1">
        <p style="color: red;">Error saving data: {{ error.s1 }}</p>
      </div>
    </section>

    
  </div>
</template>

<script>
import { fetchT1Data, fetchW1Data, saveData } from '../api/apiService.js';

export default {
  data() {
    return {
      t1Data: null,
      w1Data: null,
      userInput: '', // 新增用於存儲用戶輸入的數據
      responseMessage: null, // 新增用於存儲後端回傳的消息
      loading: {
        t1: false,
        w1: false,
      },
      error: {
        t1: null,
        w1: null,
        s1: null,
      },
    };
  },
  methods: {
    async fetchData(api) {
      this.loading[api] = true;
      this.error[api] = null;

      try {
        let response;
        if (api === 't1') {
          response = await fetchT1Data();
          this.t1Data = response.data
        } else if (api === 'w1') {
          response = await fetchW1Data();
          this.w1Data = response.data
        } 

      } catch (error) {
        this.error[api] = error.message;
      } finally {
        this.loading[api] = false;
      }
    },
    async save() {
      
      const payload = {
        name: this.userInput
      };

      try {
        const response = await saveData(payload); // 假設這是用於發送消息的 API
        this.responseMessage = response.result; // 提取回應的數據
        this.userInput = ''; // 清空輸入框
      } catch (error) {
        console.error('Error saving log:', error);
        this.error = error;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
section {
  margin: 20px;
}
button {
  padding: 8px 16px;
  margin: 8px 0;
  font-size: 16px;
  cursor: pointer;
}
</style>
